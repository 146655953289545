/*header*/
h1 {
    font-family: var(--wswc-font);
    font-weight: normal;
    font-size: 128px;
    text-align: center;
    color: var(--wswc-red);
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
    width: 60vw;
    margin-top: 0;
}
