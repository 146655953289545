.fancy-button {
    display: inline-block;
    color: white;
    height: 15vh;
    width: auto;
    border: solid 4px white;
    background-color: var(--wswc-red);
    border-radius: 20px;
    font-family: var(--wswc-font), serif;
    font-size: 4vw;
    cursor: pointer;
    box-shadow: 0 15px #880404;
    transition: 0.1s;
    padding: 0 3rem 0 3rem;
    text-shadow: 1px 2px 5px #500000;
}

.fancy-button:active {
    box-shadow: 0 6px #880404;
    transform: translateY(9px);
}


.fancy-button.disabled-dude {
    color: lightgray;
    box-shadow: 0 6px #2d2d2d;
    background-color: dimgray;
    border: solid 4px lightgray;
    text-shadow: none;

    transform: none
}