.become-button {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.become-button button {
    font-size: 10vw;
    height: 10vh;
    margin: 1rem 0 0 0;
}

.cool-input {
    background-color: white;
    padding: 1.5rem;
    border: 4px solid var(--wswc-red);
    border-radius: 20px;
    font-family: var(--wswc-font), serif;
    font-size: 10vw;
    text-align: center;
    color: var(--wswc-red);
    margin: 1rem;
    max-width: 75%;
}

.cool-input::placeholder {
    color: rgba(138, 138, 138, 0.3);
    text-shadow: 0 0;
}

.player-lobby-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100%;
    margin: 10vh 0 0 0;
}

.player-lobby-holder p {
    color: var(--wswc-red);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 56px;
}

.name-options {
    display: flex;
}

.options {
    display: flex;
    flex-direction: column;
}

.red-box {
    color: white;
    background-color: var(--wswc-red);
    border: 4px solid white;
    padding: 10px 25px;
    border-radius: 25px;
    font-size: xxx-large;
    font-weight: bolder;
}

.dropdown {
    margin-top: 1rem;
    color: var(--wswc-red);
    z-index: 2;
}

.dropdown select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
}
