.music-container {
    position: absolute;
    left: 0;
    top: 0;
}

.mute-toggle {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    color: white;
    font-size: xxx-large;
}

.icon {
    font-size: 2.5rem;
}