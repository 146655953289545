.choice-container .choices {
    display: flex;
    flex-direction: column;
}

.choice-container .choice {
    height: 15vh;
    width: auto;
    margin: 0.5rem;
}

.player-wait-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 0 0;
    width: 100vw;
    height: 100%;
}


.loading-gif {
    width: 50vw;
    height: auto;
    margin: 1rem;
}
