.stamp {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stamp img {
    height: auto;
    width: 50vw;
}

@media screen and (max-width: 600px) {
    .stamp img {
        height: auto;
        width: 90vw;
    }
}