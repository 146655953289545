.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.player-answer-count {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 15%;
    font-size: 3vw;
    -webkit-text-stroke: 2px white;
}

.borp .fancy-textbox {
    /*font-size: 3vw;*/
    margin: 0 1rem 0 1rem;
}

@media screen and (max-width: 600px) {
    .borp .fancy-textbox {
        /*font-size: 8vw;*/
    }
}

.container .fancy-textbox {
    margin-top: 0;
}

.quote-container .fancy-textbox {
    font-size: 5vh;
}

.quote-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.borp .bob {
    position: relative;
    display: flex;
    justify-content: center;
}

.borp {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
}

.choices {
    display: flex;
}

.quote-image {
    height: 20vh;
    border-radius: 20px;
    border: white 4px solid;
}
