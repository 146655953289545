.fancy-textbox {
    background-color: var(--wswc-red);
    padding: 1.5rem;
    border: 4px solid white;
    border-radius: 20px;
    font-family: var(--wswc-font);
    font-size: 5vh;
    text-align: center;
    text-shadow: 1px 2px 5px #500000;
    color: white;
    margin: 1rem;
    max-width: 75%;
    width: 75vw;
    white-space: pre-line;
}
