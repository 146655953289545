.choice {
    font-family: var(--wswc-font);
    color: white;
    background-color: var(--wswc-red);
    padding: 0.5rem;
    /*height: 4rem;*/
    /*width: 4rem;*/
    align-content: center;
    margin: 1rem;

    border: 4px solid white;
    border-radius: 20px;

    height: auto;
    width: 13vw;
}

.choices {
    /*margin: 1rem*/
}
