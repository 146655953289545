.hihinam {
    display: flex;
    height: 80vh;
    position: relative;
}

.judge-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 45vw;

    background-color: var(--wswc-red);
    padding: 1.5rem;
    border: 4px solid white;
    border-radius: 20px;
}

.hihinam > * {
    margin: 0 1rem 0 1rem;
}

#wswc-link {
    text-align: center;
    font-size: 48px;
    font-weight: lighter;
    font-family: var(--wswc-font);
    color: var(--wswc-red);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    position: relative;
    bottom: 12px;
    z-index: 1;
}

b {
    font-weight: lighter;
}

.judge-names {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;
}

.player-name {
    margin: 10px;
    font-size: 2.5vw;
    background-color: #F15405;
    padding: 1rem;
    border-radius: 10px;
}

.bob-button {
    text-align: center;
    max-width: 80%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sniffing-bob {
    max-height: 60px;
    width: 100px;
    object-fit: contain;
}

.bob-button .fancy-button {
    margin: 0.5rem 0 0 0;
}

/* Dropdown Styles */
.dropdown {
    margin-top: 1rem;
    color: var(--wswc-red);
    z-index: 2;
}

.dropdown select {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
}

.dropdown select:focus {
    outline: none;
    border-color: var(--wswc-red);
}

.icon-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settings {
    font-size: 2.5rem;
    margin: 0 0 0 0.5rem;
    cursor: pointer;
}

.modal-content {
    color: var(--wswc-red);
}

.modal-content .dropdown {
    margin: 1rem;
    width: 20vw;
}

.modal-content .fancy-button {
    height: 10vh;
    font-size: 5vh;
    margin: 1rem;
    border: solid 4px white;
}

.modal-content h2 {
    color: white;
}

.modal-content .dropdown span {
    color: white;
    font-size: 2vw;
    font-family: var(--wswc-font);
}

.modal-content .dropdown select {
    font-size: 2vw;
    font-family: var(--wswc-font);
}

.header-content {
    display: flex;
    justify-content: space-between;
}

.header-content a {
    color: white;
    font-size: xx-large;
}