.choose-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.win-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.win-container .fancy-textbox {
    margin: 0 2vh 2vh 2vh;
    font-size: 10vh;
}

.fnaf-confetti {
    position: absolute;
    z-index: 999;
    width: auto;
    height: 100vh;
    bottom: 0;
    left: 0;
}

.everything-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.everything-container .fancy-button {
    max-width: 25vw;
}
