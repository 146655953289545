.quote-author {
    font-family: var(--wswc-font);
    color: white;
    background-color: var(--wswc-red);
    padding: 0.5rem;
    align-content: center;

    border: 4px solid white;
    border-radius: 20px;

    height: auto;
    width: 30vw;
}

.answer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .quote-author {
        height: auto;
        width: 70vw;
    }
}
