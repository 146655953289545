.tutorial-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    max-width: 60vw;
    height: 50vh;

    background-color: var(--wswc-red);
    padding: 1.5rem;
    border: 4px solid white;
    border-radius: 20px;
}

.bob {
    position: absolute;
    width: 100%;
    bottom: 100%;
}

.image-div {
    position: absolute;
    width: 100%;
    bottom: 100%;
}

.talking-stick {
    height: 7vh;
    width: auto;
}

.text-container p {
    font-size: calc(2.5vw + 2.5vh);
    text-align: center;
}

.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.botton {
    text-align: right;
    margin-top: 10px;
}


.bar-wrapper {
    border: 3px solid white;
    border-radius: 5px;
    width: 100%;
}

.bar-container {
    background-color: white;
}
