.hasToSip {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sip-button {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 5vh 0;
}

.sip-button .fancy-button {
    font-size: 10vw;
    height: 10vh;
}

.drink {
    height: 25vh;
    border-radius: 50px;
    width: auto;
    border: 4px solid white;
}
